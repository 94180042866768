import coverImageFragment from './cover-image'
import sectionsFragment from './sections'
import timestampsFragment from './timestamps'

const audioFileFragment = `
  id
  titleDe
  fileDe {
    id
    key
    generalType
    filename(withExtension: true)
  }
`

export default `
  id
  titleDe
  slugDe
  coverImage {
    ${coverImageFragment}
  }
  audioFile {
    ${audioFileFragment}
  }
  audioFileTwo {
    ${audioFileFragment}
  }
  audioFileThree {
    ${audioFileFragment}
  }
  sections {
    ${sectionsFragment}
  }
  recipe {
    id
    titleDe
    editingStatus
  }
  ${timestampsFragment}
`
